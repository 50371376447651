<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="main-wrap">
        <div class="mamber-information-wrap">
          <div class="avatar-wrap" @click="toolClick('memberInfo')">
            <van-image :src="user_info.avatar" class="avatar" fit="cover" />
          </div>
          <div class="mamber-information">
            <div class="nickname">{{user_info.nick_name}}</div>
            <div class="member-level">{{user_info.group_name}}</div>
            <div class="member-level-star">
              <img src='https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/star.svg' v-for="item in user_info.starNumber" :key="item" class="star" />
            </div>
          </div>
          <div class="bottom-wrap">
            <div class="bottom-item-wrap" @click="toolClick('integralHistory')">
              <div class="key">
                邻里值
              </div>
              <div class="value">
                {{user_info.reward}}
              </div>
            </div>
            <div class="bottom-item-wrap" @click="toolClick('exchangeList')">
              <div class="key">
                兑换
              </div>
			  <div class="value">
			    <div class="iconfont_program icon-duihuanzhongxin" style="font-size:18px"></div>
			  </div>
       <!--       <div class="value">
                {{user_info.balance}}
              </div> -->
            </div>
            <div class="bottom-item-wrap" @click="toolClick('couponList')">
              <div class="key">
                优惠券
              </div>
              <div class="value">
                <div class="iconfont_program iconyouhuiquan" style="font-size:18px"></div>
              </div>
            </div>
          </div>

          <div class="fixed">
            <div class="setting" @click="toolClick('memberInfo')">
              <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/shezhi.svg" mode="widthFix" />
            </div>
            <div class="sign" @click="sigTap">
              <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/qiandao.svg" mode="widthFix" />
            </div>
          </div>

        </div>

        <div class="qrcode-main-wrap" :class="showcode?'showcode':''">
          <div class='codeWarp'>
            <div class='code' id="myQrcode"></div>
            <div class='refresh' @click='refreshTap' v-if="clear">
              <div class='time'>
                <span class='iconfont_program iconshuaxin1'></span>
                <span>{{codeTime}} 秒后自动</span>
                <span class="blue">刷新</span>
              </div>
            </div>
            <div class='refresh' v-else>
              <div class='time'>
                <span class='iconfont_program iconshuaxin1'></span>
                <span>刷新中</span>
              </div>
            </div>
          </div>
          <div class='desc'>请您的客户经理扫码，帮您升级</div>
        </div>

        <div class="service-main-wrap">
          <div class="qrcode-wrap">
            <div @click="erewimaTap" v-if="!showcode">
              <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member-qrcode.png" mode="widthFix" class="qrcode" />
            </div>
            <div @click="closecodeTap" v-else>
              <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member-qrcode1.png" mode="widthFix" class="qrcode" />
            </div>
            <div class="description">
              扫描二维码畅享多重好礼
            </div>
          </div>
          <div class="service-wrap" v-if="customer_service">
            <div class="information-wrap">
              <div class="avatar-wrap">
                <img :src="customer_service.service_avatar" mode="widthFix" />
              </div>
              <div class="name-wrap">
                <div class="name">
                  {{customer_service.service_nickname}}
                  <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/fengche.svg" mode="widthFix" />
                </div>
                <div class="level">品牌顾问</div>
              </div>
              <div class="iconfont_program iconzu3" @click="serviceUrl"></div>
              <a class="iconfont_program iconzu2" :href="'tel://'+customer_service.service_mobile"></a>
            </div>
            <div class="description">“尊敬的邻里会员您好，我是您的专属客服，感谢您选择小院生活，随时恭候您的光临。”</div>
          </div>
        </div>

<!--        <div class="tool-wrap">
          <div class="tool-item-wrap">
            <div class="tool-name">钱包</div>
            <div class="tool-description">充值尽享优惠</div>
            <img mode="widthFix" src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/qianbao.png" />
          </div>
          <div class="tool-item-wrap" @click="toolClick('exchangeList')">
            <div class="tool-name">兑换</div>
            <div class="tool-description">邻里好礼兑换</div>
            <img mode="widthFix" src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/duihuan.png" />
          </div>
          <div class="tool-item-wrap" @click="toolClick('feedback')">
            <div class="tool-name">建议</div>
            <div class="tool-description">百倍用心十分满意</div>
            <img mode="widthFix" src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/jianyi.png" />
          </div>
        </div> -->

        <div class="main-tool-wrap">
      <!--    <div class="icon-tool">
            <span class="iconfont_program iconhouse"></span>
          </div> -->
<!--          <div class="item-wrap" @click="toolClick('appointment')">
            <div class="iconfont_program">
              <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/yuyue.svg" mode="widthFix" />
            </div>
            <div class="item-name">预约订单</div>
          </div>
          <div class="item-wrap" @click="toolClick('couponList','order')">
            <div class="iconfont_program">
              <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/liquan.svg" mode="widthFix" />
            </div>
            <div class="item-name">我的礼券</div>
          </div>
          <div class="item-wrap" @click="toolClick('integralHistory')">
            <div class="iconfont_program">
              <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/xiaofei.svg" mode="widthFix" />
            </div>
            <div class="item-name">我的邻里值</div>
          </div> -->
		  <div class="item-wrap" @click="menuClick(item.link)" v-for="(item,index) in toolList" :key="index">
		    <div class="iconfont_program">
		      <img :src="item.img" mode="widthFix" />
		    </div>
		    <div class="item-name">{{item.name}}</div>
		  </div> 
        </div>
      </div>
      <div class="login-out-wrap">
        <div class="login-out" @click="loginOut">
          退出登录
        </div>
      </div>
      <van-popup v-model="sign.show" bind:close="closeSign" class="sign-popup">
        <div class="sign-wrap">
          <div class="sign-main">
            <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/qiandao/qiandaobg.png" mode="widthFix" />
            <div class="message">{{sign.message}}</div>
            <div class="continuous-day">已连续签到{{sign.continuous_sign_day}}天</div>
            <div class="integration-wrap">
              <span>邻里值</span>
              <span class="integration">+{{sign.integration}}</span>
            </div>
          </div>
          <div class="close-sign iconfont_program iconguanbi" @click="closeSign"></div>
        </div>
      </van-popup>
    </div>
    <Footer></Footer>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/head2022.vue'
import Footer from '@/components/foot2022.vue'
import utils from '@/common/utils'
import QRCode from 'qrcodejs2'
import md5 from 'md5'

export default {
  name:"member",
  data () {
    return {
      showcode: false,
      appCount: '',
      clear: true,
      codeTime: 60,
      myQrcode: null,
      sign: {
        show: false,
        message: "",
        continuous_sign_day: 0,
        integration: 0,
      },
	  toolList:[
	  	{
	  		name:' 预约订单',
	  		img:'https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/jieshu.svg',
	  		link: {
	  			pathName:'appointment',
	  		}
	  	},
	  	{
	  		name:' 我的礼券',
	  		img:'https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/liquan.svg',
	  		link: {
	  			pathName:'couponList',
	  			params:'order'
	  		}
	  	},
	  	{
	  		name:' 我的邻里值',
	  		img:'https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/xiaofei.svg',
	  		link: {
	  			pathName:'integralHistory',
	  		}
	  	},
	  	{
	  		name:' 我的设计',
	  		img:'https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/drink.svg',
	  		link: {
	  			// url:'http://xy.xiaoyuan.com.cn/index.php?ctl=uc_designs#',//test
				url:'http://mall.xiaoyuan.com.cn/index.php?ctl=uc_designs#',//formal
	  		}
	  	},
	  	{
	  		name:'家居优惠券',
	  		img:'https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/youhuiquan.svg',
	  		link: {
	  			// url:'http://xy.xiaoyuan.com.cn/index.php?ctl=uc_youhui#',//test
				url:'http://mall.xiaoyuan.com.cn/index.php?ctl=uc_youhui#',//formal
	  		}
	  	},
	  	{
	  		name:' 家居订单',
	  		img:'https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/dingdan.svg',
	  		link: {
	  			// url:'http://xy.xiaoyuan.com.cn/index.php?ctl=uc_order#',//test
				url:'http://mall.xiaoyuan.com.cn/index.php?ctl=uc_order#',//formal
	  		}
	  	},
	  	{
	  		name:' 商户付款',
	  		img:'https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/kehu.svg',
	  		link: {
	  			// url:'http://xy.xiaoyuan.com.cn/index.php?ctl=uc_supplierpay#',//test
				url:'http://mall.xiaoyuan.com.cn/index.php?ctl=uc_supplierpay#',//formal
	  		}
	  	},
		{
			name:' 我的建议',
			img:'https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/jieyuezhong.svg',
			link: {
				pathName:'feedback',
			}
		},
	  ]
    }
  },
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapState(['user_info', 'ocApi', 'mallApi','customer_service']),
  },
  methods: {
    toolClick: utils.debounce(function (name,e) {
      this.$router.push({
        name,
		params: {
			tagName: e,
		}
      })
    }, 200, true),
	menuClick(e){
		if (e.url) {
			window.open(e.url)
			return;
		}
		if (e.pathName == this.$route.name) {
			this.reload()
		}
		this.$router.push({
			name: e.pathName,
			params: {
				paramsName: e.params,
			}
		});
	},
    erewimaTap: utils.debounce(function () {
      let that = this;
      clearInterval(that.appCount);
      that.showcode = true
      that.getCode();
    }, 200, true),
    refreshTap: utils.debounce(function () {
      let that = this;
      clearInterval(that.appCount);
      that.getCode();
    }, 200, true),
    closecodeTap: utils.debounce(function () {
      let that = this;
      clearInterval(that.appCount);
      that.showcode = false
    }, 200, true),
    getCode: function () {
      var that = this;
      let code = that.user_info.telephone;
      let time = new Date().getTime();
      let hash = JSON.stringify({
        hash: md5(md5(code + '' + time)),
        code,
        time
      })
      that.clear = false
      that.codeTime = 60

      if (that.myQrcode instanceof QRCode) {
        that.myQrcode.makeCode(hash)
      } else {
        that.myQrcode = new QRCode('myQrcode', {
          width: 175,
          height: 175,
          text: hash, // 二维码地址
          colorDark: "#000",
          colorLight: "#fff",
        })
      }

      setTimeout(function () {
        that.appCount = setInterval(function () {
          that.codeTime = that.codeTime - 1
          if (that.codeTime == 0) {
            clearInterval(that.appCount);
            //取消指定的setInterval函数将要执行的代码 
            that.getCode();
          }
        }, 1000)
        that.clear = true
      }, 300)

    },
    serviceUrl: utils.debounce(function () {
      window.location.href = this.customer_service.url
    }, 200, true),

    sigTap: utils.debounce(function () {
      let that = this;
      var userinfo = this.user_info;

      that.$axios.post(that.ocApi + 'user/sign', {
        'telephone': userinfo.telephone,
      })
        .then(res => {

          if (res.data.code == 1) {
            that.sign = {
              show: true,
              message: res.data.message,
              continuous_sign_day: res.data.data.continuous_sign_day,
              integration: res.data.data.integration,
            }
            that.$store.dispatch('updateMember')
          } else {
            that.$toast.fail(res.data.message);
          }

        })
        .catch(error => {
          that.$toast.fail(error.response.data.message);
        });

    }, 1000, true),
    closeSign: utils.debounce(function () {
      this.sign = {
        show: false,
        message: "",
        continuous_sign_day: 0,
        integration: 0,
      }

    }, 1000, true),
    loginOut: utils.debounce(function () {
      let that = this;
      that.$toast({
        message: '退出成功',
        type: 'success'
      });
	  that.$axios.get(that.mallApi, {
	  	params: {
	  		ctl: 'user',
	  		act: 'xyloginout'
	  	}
	  })
      that.$store.dispatch('loginLogout',null)
      setTimeout(() => {
        that.$router.replace({
          name: 'index'
        });
      }, 1000);

    }, 500, true),

  },
}
</script>

<style lang="scss" scoped>
.main-wrap {
  background-image: url(https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member-bg.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  padding-top: 100px;
  .mamber-information-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items: center;
    box-shadow: 0px 3px 5px 0px rgba(221, 221, 221, 0.34);
    border-radius: 4px;
    padding: 0 15px;
    box-sizing: border-box;
    padding-bottom: 20px;
    position: relative;
    .avatar-wrap {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      overflow: hidden;
      margin-top: -40px;
      .avatar {
        width: 100%;
        height: 100%;
      }
    }
    .mamber-information {
      margin-top: 8px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .nickname {
        font-size: 18px;
        font-weight: bold;
        color: rgba(71, 77, 101, 1);
        line-height: 18px;
      }
      .member-level {
        background: linear-gradient(
          180deg,
          rgba(27, 215, 255, 1),
          rgba(0, 235, 87, 1)
        );
        box-shadow: 0px 3px 5px 0px rgba(221, 221, 221, 0.34);
        font-size: 10px;
        color: #fff;
        border-radius: 50px;
        display: inline-block;
        padding: 3px 8px;
        margin-top: 7px;
      }
      .member-level-star {
        display: flex;
        margin-top: 7px;
        .star {
          width: 10px;
          height: 10px;
          margin: 0 2px;
        }
      }
    }
    .bottom-wrap {
      width: 100%;
      display: flex;
      border-top: 1px solid #f8f8f8;
      padding-top: 22px;
      margin-top: 13px;
      .logintip {
        text-align: center;
        width: 100%;
      }
      .bottom-item-wrap {
        flex: 1;
        text-align: center;
        .key {
          font-size: 12px;
          color: #a3a3a3;
        }
        .value {
          font-size: 21px;
          font-weight: bold;
          line-height: 33px;
          background: linear-gradient(
            90deg,
            rgba(27, 215, 255, 1),
            rgba(0, 235, 87, 1)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          width: 100%;
          img {
            width: 35px;
            margin: 0 auto;
            margin-top: 6px;
          }
        }
      }
    }
    .fixed {
      position: absolute;
      top: 10px;
      right: 2px;
      display: flex;
      .setting,
      .sign {
        width: 25px;
        margin-right: 8px;
        img {
          width: 100%;
        }
      }
    }
  }
  .qrcode-main-wrap {
    width: 100%;
    margin-top: 0px;
    border: 1px solid rgba(248, 248, 248, 1);
    box-shadow: 0px 3px 5px 0px rgba(221, 221, 221, 0.34);
    border-radius: 4px;
    background-color: #fff;
    text-align: center;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
    &.showcode {
      margin-top: 13px;
      max-height: 1000px;
    }
    .codeWarp {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .refresh {
        margin-top: 10px;
        font-size: 13px;
        color: #999999;
        .time {
          .iconfont_program {
            margin-right: 3px;
            font-size: 13px;
          }
          .blue {
            margin-left: 2px;
            color: #03a3ff;
          }
        }
      }
    }
    .desc {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid #f8f8f8;
      color: #333333;
      font-size: 15px;
    }
  }
  .service-main-wrap {
    width: 100%;
    margin-top: 13px;
    border: 1px solid rgba(248, 248, 248, 1);
    box-shadow: 0px 3px 5px 0px rgba(221, 221, 221, 0.34);
    border-radius: 4px;
    background-color: #fff;
    .qrcode-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      .qrcode {
        width: 80px;
        margin-top: -15px;
      }
      .description {
        font-size: 12px;
        color: #a3a3a3;
        margin-top: 10px;
      }
    }
    .service-wrap {
      width: 100%;
      padding: 15px 20px;
      box-sizing: border-box;
      border-top: 1px solid #f8f8f8;
      margin-top: 25px;
      .information-wrap {
        display: flex;
        align-items: center;
        .avatar-wrap {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .name-wrap {
          margin-left: 16px;
          flex: 1;
          .name {
            font-size: 20px;
            color: #474d65;
            display: flex;
            align-items: center;
            img {
              width: 16px;
              margin-left: 2px;
            }
          }
          .level {
            font-size: 12px;
            color: #7d8090;
            margin-top: 7px;
          }
        }
        .iconzu3 {
          font-size: 36px;
          color: #818392;
        }
        .iconzu2 {
          font-size: 36px;
          color: #818392;
          margin-left: 15px;
        }
      }
      .description {
        width: 100%;
        padding: 7px 15px;
        box-sizing: border-box;
        background-color: #fafafa;
        border-radius: 6px;
        font-size: 12px;
        color: #7d8090;
        margin-top: 15px;
      }
    }
  }

  .tool-wrap {
    width: 100%;
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    .tool-item-wrap {
      width: 32%;
      text-align: center;
      box-shadow: 0px 3px 5px 0px rgba(221, 221, 221, 0.34);
      border-radius: 4px;
      .tool-name {
        font-size: 19px;
        color: #464c65;
        font-weight: 800;
        margin-top: 10px;
      }
      .tool-description {
        font-size: 12px;
        color: #464c65;
        margin-top: 8px;
      }
      img {
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  .main-tool-wrap {
    width: 100%;
    margin-top: 13px;
    display: flex;
	flex-wrap: wrap;
    padding: 22px 10px 2px;
    box-sizing: border-box;
    box-shadow: 0px 3px 5px 0px rgba(221, 221, 221, 0.34);
    position: relative;
    border-radius: 4px;
    // overflow: hidden;
    .item-wrap {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
	  margin-bottom: 20px;
      .iconfont_program {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
        }
      }
      .item-name {
        font-size: 13px;
        color: #464c65;
        margin-top: 5px;
        text-align: center;
      }
    }
    .icon-tool {
      background: linear-gradient(
        180deg,
        rgba(27, 215, 255, 1),
        rgba(0, 235, 87, 1)
      );
      display: inline-block;
      width: 29px;
      height: 29px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0 0 100% 0;
      .iconfont_program {
        color: #ffffff;
        position: absolute;
        top: 4px;
        left: 4px;
        font-size: 16px;
      }
    }
  }
}
.login-out-wrap {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  .login-out {
    color: #00d596;
    display: inline-block;
  }
}
.sign-popup {
  &.van-popup {
    background-color: initial;
    width: 100%;
  }
  .sign-wrap {
    width: 80%;
    margin: auto;
    .sign-main {
      width: 100%;
      position: relative;
      img {
        width: 100%;
      }
      .message {
        width: 100%;
        position: absolute;
        top: 39%;
        text-align: center;
        font-size: 20px;
        color: #ffffff;
      }
      .continuous-day {
        width: 100%;
        position: absolute;
        top: 49%;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
      }
      .integration-wrap {
        width: 100%;
        position: absolute;
        top: 91%;
        text-align: center;
        font-size: 13px;
        color: #343434;
        .integration {
          color: #00bea4;
        }
      }
    }
    .close-sign {
      border: 2px solid #ffffff;
      color: #ffffff;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 15px;
      margin: auto;
      margin-top: 35px;
    }
  }
}
</style>